@import url('https://fonts.googleapis.com/css2?family=Abyssinica+SIL&family=Assistant:wght@300;400;500&family=Poppins:wght@400;500;600&display=swap');


*{
  margin:0;
  padding: 0;
  box-sizing: border-box;
}

body{
  font-family: 'Assistant', sans-serif;
  color:rgb(37, 85, 157);

}

html{
  scroll-behavior: smooth;
}
.section-cover{
  background-color: rgb(242, 242, 242);
  background-color: rgb(238, 238, 249);
}

.section-cover2{
  background-color:#333e50;
}

@media screen and (min-width:800px){
.section-cover3 {
    padding-top: 10rem;
    padding-bottom: 5rem;
  }
}



.top-cover{
  border-bottom: 0.15rem solid #ec2626;
    border-bottom: 0.2rem solid rgb(245, 182, 55);
  background-color: rgb(235, 241, 255);
}

@media screen and (max-width:1000px) {
  .top-cover {
    display: none;
  }
}

.top-section1{
  padding:0.3rem 0;
  width:90vw;
  max-width: 1170px;
  margin:0 auto;

  
}
.top-location-contact-email{
  display: flex;
  align-items: center;
  /* justify-content: center; */


}

.top-location-contact-email span{
display:inline-block;
color: #0D18F3;
  /* color: rgb(228, 178, 85); */
  font-size: 1rem;
  /* margin-right: 0.5rem; */
}

.top-icon{
  color:#ec2626!important;
  line-height: 1.1rem;
  margin-right: 0.5rem;
}

.my-container{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
 
}

img{
  width:100%;
}




/*Nav*/

.nav{
  padding:0.5rem 0;
  padding-top: 0;
    /* padding-bottom: 1rem; */
  border-bottom: 0.1rem solid #ffffff;
  /*background-color: #910c11; */
}



.nav-center{
  width:90vw;
  margin:0 auto;
  max-width: 1170px;
display: flex;
justify-content: space-between;
align-items: center;
flex-wrap: wrap;


}

  .nav-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width:100%;
  
}

 .logo-container{
  max-width:8rem;
  padding:0.5rem 0;
}

.nav-close,
.nav-toggle {
  cursor: pointer;
  font-size: 2rem;
  color:#a32cc4;
}

.nav-toggle.hide{
  display: none;
}

.nav-close.hide{
  display: none;
}


 
.nav-links{

  list-style: none;
  position: relative;
  min-width: 15rem;
}

.nav-links li{
    height: 3rem;
  display: flex;
    align-items: center;
}

.drop-down-li{
  /* background-color: aquamarine; */
  /* padding-bottom: 2rem; */
  height: 6rem;
}


.nav-link1 {
  color:#a32cc4;
    transition: 0.5s;
    text-decoration: none;
    font-size: 1.2rem;
 margin-bottom: 1.2rem;
    cursor: pointer;
    display: inline-block;
    /* text-transform: uppercase; */
    font-weight: 500;
transition:0.5s;
}

.nav-link1:hover{
  color:#f04a00;
}


.nav{
box-shadow: 0 0 0.3rem grey;
}

.links-container{
  height: 0 ;
  overflow: hidden;
  transition: 0.5s;
  
}

.drop-down-parent{
position: relative;
}




.drop-down{
 position: absolute;
z-index: 2;
background-color: white;
/* box-shadow: 0 0 0.3rem grey; */
/* border-radius: 0.3rem; */
list-style: none;
min-width: 8rem;
/* min-width: 12rem; */
/* left:2rem; */
left:7.5rem;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
/* background-color: rgb(0, 0, 0); */

/* background-color: #25d366; */
/* padding-right: 1rem; */

/* top:7rem; */
top:3.5rem;
  
}

@media screen and (max-width:800px){
  .drop-down{
padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  }
}

.drop-down.hide{
  display: none;
}



.drop-down li {
 background-color: #0D18F3;
 background-color: #242021;
 background-color: #013e6d;
 margin-left: -2rem;
min-width: 8rem;
/* padding-left: 1rem; */
/* border-radius: 0.3rem; */
transition: 0.5s;
border-top: 0.1rem solid rgb(255, 255, 255);
height: 2.5rem;;
width: 100%;
text-align: center;
padding-top: 0.5rem;
padding-bottom: 0.5rem;
line-height: 1rem;
}

.drop-down li:hover {
  background-color: #418e38;
}

.drop-down li:first-child {
  
  border-top: none;
}

@media screen and (max-width:800px){
.drop-down-li{
  text-align: center;
}

  .drop-down li:last-child{
    padding-top: 1rem;
    
  }

     .drop-down li:first-child {
       padding-bottom: 0.5rem;
  
     }
}



.drop-down li:hover .drop-down-link {
  /* background-color: rgb(15, 36, 68); */
 
  color:rgb(226, 153, 43);
  color: #f38c37;
  color: #fffdfb;

}



.drop-down-link{
    text-decoration: none;
    display: block;
    padding:0.2rem 0;
    width:100%;
    color:white;
    /* text-transform: uppercase; */
    /* padding-right: 1rem; */
}



.drop-down-link:hover {
  color: white;
}












@media screen and (min-width:800px) {

.drop-down{
left:8rem;
top:3rem;

}


  .nav {
    padding-bottom: 0;
  }

  .nav-toggle,
  .nav-close {
    display: none;
  }

  .nav-header {
    width: auto;
  }

  .links-container {
    height: auto !important;
 overflow: visible;
  }

  .nav-links {
    display: flex;
  }

  .nav-link1 {
    margin-left: 1.5rem;
    margin-bottom: 0;
    /* height: 5rem; */
    /* position: relative; */
  }
}

/*Fixed Nav*/

@media screen and (min-width:800px){
.fixed-nav {

    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 3;
    background-color: white;
    box-shadow: 0 0 0.3rem rgb(4, 30, 40);

  }

    
}



.fixed-nav .logo-container {
  max-width: 12rem;
}

.fixed-nav .nav-link1{
  /* background-color: aqua; */
  margin-top: 1rem;
}

/*Home Slides*/

  /* .d-block{
    height:80vh;
    object-fit: cover;
  } */
.home-main-slides .d-block{
height: 100vh;
}

@media screen and (max-width:800px){
.home-main-slides .d-block {
    height: auto;
  }
}



.slide-h3{
 background-color:#f04c00d1; 
 max-width:20rem;
 margin:0 auto;
 font-size: 2.5rem;
  font-family: 'Assistant', sans-serif;
  padding-bottom: 0.2rem;
  letter-spacing: 0.15rem;
  text-shadow: 0 0 0.3rem #000000;
}

.slide-p{
  color:white;
  background-color: rgba(255, 255, 255, 0.846);
  background-color:#a32cc4c9;
  /* background-color:#C00000; */
  font-size: 1.5rem;
 text-shadow: 0 0 0.3rem black;
  max-width: 30rem;
  margin: 0 auto;
  margin-top: 1rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  line-height: 1.8rem;
  padding-bottom: 0.5rem;
}

@media screen and (max-width:600px){
  .slide-h3{
    font-size: 1.5rem;
    line-height: 1.5rem;
  }
    .slide-p {
      font-size: 1rem;
      line-height: 1.3rem;
    }
}

.slide-p-a{
  max-width: 20rem;
}

.slide-p1{
  max-width:20rem;
}

.slide-p4{
  max-width: 32rem;
}

/* @media screen and (min-width:800px){

  .slide-h3 {
      font-size: 3.5rem;
      max-width: 36rem; 
    }
  .slide-p{
      font-size: 2.5rem;
 line-height: 3rem;
  }
  .slide-p1 {
    max-width: 25rem;
  }
    .slide-p3{
      max-width:33rem;
    }
} */
/*Home Page*/
.bg-cover{
  background-color: rgb(243, 248, 251);
background-color: rgb(255, 255, 255);
background: linear-gradient(to bottom right, #a32cc4,#ffffff,#ffffff,#ffffff,#ffffff,#ffffff,#ffffff,#f04a00);
}
.content-container{
  width:90%;
  max-width: 1170px;
  margin:0 auto;
  padding:3rem 0;
}

.content-container1 {
  text-align: center;
}

.content-container2{
  padding-top: 0rem;
}

.home-p{
  font-size: 1.2rem;
    text-align: justify;
    color: #616161;
    /* color:#013e6d; */
}

.home-h2{
color: #a32cc4;
  /* color:#41341b; */
  text-align: center;
  /* font-family: 'Abyssinica SIL', serif; */
  font-size: 2.5rem;
}

.goals-container{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;

}

.goals-container1{
  flex-direction: row-reverse;
}

.goals-slides, .goals-text{
  flex: 1 25rem;
  max-width: 30rem;
  margin: 1rem;
  
}

.goals-text1{
  max-width: 100%;
}

.goals-text1{
  max-width: 60rem;
}

.goals-text2{
  box-shadow: 0 0 0.5rem #595959;
 max-width:18rem;
}

.goals-text{
  background-color: white;
  /* border-radius: 0.5rem; */
  padding:1rem; 
}

.goals-text3{
  max-width: 24rem;
}

.goals-text4{
max-width:50rem;
}

.goals-text5 {
  max-width: 1170px;
}

.goal{
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
 
  
}

.goal-icon{
 color:#f04a00;
 display: inline-block;
 font-size: 2rem;
 margin-right: 0.5rem;
 margin-top: -0.9rem;
}
.goal-description{
  font-size: 1.1rem;
  color: #616161;
  text-align: justify;
}

.goal-description1{
    font-size: 1.1rem;
      color: rgb(15, 64, 137);
      text-align: justify;
}

.goal-description-a{
  text-align: left;
}

.goals-h2{
  margin-bottom: 1rem;
  color:#016734;
}

.goals-carousel .d-block{
  border-radius: 0.5rem;
}

.concrete-carousel{
  box-shadow: 0 0 0.6rem rgb(73, 73, 73);
  border-radius: 0.3rem;
}


@media screen and (max-width:1140px){
.goals-carousel .d-block, .concrete-carousel .d-block {
   height: 30rem;
  /* height: 35rem; */
  }
}



  .concrete-carousel .d-block {
    height: 20rem;
   
    border-radius: 0.3rem;
  }


/*About*/
.wwa-h2{
  padding-top: 3rem;
}
.about-img {
  width: 100%;
  border-radius: 50%;
  /* justify-self: center; */
  border: 0.4rem solid rgb(152, 190, 200);
}

.home-services-container {
  /* grid-template-columns: 20rem 1fr;
  gap:3rem;
  align-items: center; */
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 2rem;
  /* background-color: aquamarine; */
}

.about-img-container,
.home-services-info {
  /* flex:1 30rem; */
  margin: 1rem;
}

.about-img-container {
  flex: 1 15rem;
  max-width: 15rem;

}

.home-services-data {
  text-align: center;
}

.home-services-info {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  /* background-color: blueviolet; */
  flex: 2 20rem;
  /* max-width: 1190px; */

}

.home-service-box {
  flex: 1 23rem;
  max-width: 23rem;
  margin: 1rem;
  /* margin-top: 2rem; */
  background-color: rgb(232, 239, 246);
  background-color:#996515;
  background-color:#2477bb;

  /* border-radius: 0.3rem; */
  padding: 1rem;
  /* box-shadow: 0 0 0.4rem rgba(9, 9, 9, 0.666); */
  display: flex;
  flex-direction: column;
  align-items: center;
  /* position: relative;
  padding-top: 3rem; */
  transition: 0.5s;
}

@media screen and (max-width:1000px) {

  .home-service-box {
    margin-top: 2rem;
  }
}

@media screen and (max-width:500px) {

  /* .about-info {
    margin-left: 0;
    margin-right: 0;
  } */
}

.home-service-box:hover {

  box-shadow: 0 0 0.5rem rgb(67, 67, 77);
}

/* .home-service-box:hover {
  background-color:  rgb(37, 85, 157);
  background-color: rgb(232, 239, 246);
} */

.home-service-box p {
  color: rgb(255, 255, 255);
  font-size: 1.1rem;
}


.home-service-box:hover p {
  color: #2a2462;
}

/* .home-service-box:hover .home-service-title {
  color:#26abe3;
} */

.home-service-icon-cover {
  border: 0.15rem solid #26abe3;
  background-color: rgb(255, 255, 255);
  border-radius: 50%;
  padding: 1rem;
  width: 4rem;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0%;
  left: 50%;
  transform: translate(-50%, -50%);



}

.home-service-icon {
  color:#26abe3;
  font-size: 2rem;
  line-height: 2rem;
  display: block;
  margin-top: -0.4rem;
}
.home-service-h2{
  color:#2477bb;
  /* color:#5dafe1; */
}
.home-service-cover{
  padding:3rem 0; 
  background-color: #fff2b8;
  background-color: #ccd7eb;
  /* background-color: #26abe3; */
}

.home-btn{
  display: inline-block;
  background-color:#2477bb;
  border:0.15rem solid #2477bb;
  border-radius: 0.5rem;
  text-decoration: none;
  color:white;
  transition: 0.5s;
  padding:0.3rem 1rem;
  margin-top: 1rem;
}

.home-btn:hover {
  border: 0.15rem solid #2477bb;
  color:#2477bb; 
  background-color: transparent;
}

/* .home-service-icon-cover{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3rem;
  width:3rem;
} */

/* .home-service-icon{
 color: rgb(226, 153, 43);
} */

.home-service-title{
  color:#e9b61d;
  /* background-color: #26abe3; */
}


/*Who We Are*/

.who-we-are-cover{
/* background-color: #ececec; */
background-color:#f1beff;
  /* background-color: rgb(244, 247, 255); */
}

/* .vm-container{
  padding-top: 1rem;
} */

.vision-mission-content{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}

.vision-mission-content-a{
  flex-direction: row-reverse;
}

.mission-info{
  flex-direction: row-reverse;
}

.vision-mission-slides,.vision-mission-info{
flex: 1 30rem;
flex-wrap: wrap;
margin: 1rem;
max-width: 30rem;
}
.vision-image{
 border-top-right-radius: 3rem;
 border-bottom-left-radius: 3rem;
}
/* .vision-mission-slides{
  box-shadow: 0.5rem -0.5rem 0 #65c164;
} */

.title-h3{
  color:#a32cc4;
  text-align: center;
}

.vision-mission-info p{
  font-size: 1.2rem;
  text-align: center;
  color: #616161;
  font-style: italic;
}

.vision-mission-info ul {
  font-size: 1.2rem;
  /* text-align: center; */
  color: #2477bb;
  max-width: 25rem;
  margin:0 auto;
}



/* .vision-mission-content-a p{
  text-align: justify;
} */


.vision-mission-info{
  background-color: white;
  padding: 1rem;
  /* border-radius: 0.5rem; */
  /* box-shadow: 0 0 0.5rem #595959; */
}

.vision-mission-info-a{
  border-radius: 1rem;
 
}

.vm-line{
  background-color: #f04a00;
  margin:0 auto;
  max-width: 5rem;
  height:0.2rem;
  margin-bottom: 1rem;
}

.core-values-container{
  max-width: 800px;
}

.core-values-h2{
  margin-bottom: 3rem;
}

.accordion-p{
  color:rgb(121, 121, 121);
  text-align: center;
  font-size: 1.1rem;
}

.accordion-h{
  font-size: 1.3rem;
    color: rgb(37, 85, 157);
  
 
}

/*Team*/

.team-cover{
  background-color: #f1beff;
}

/* .team-cover1{
  text-align: center;
} */

.table-container{
  overflow-x: auto;
}

table {
  border-collapse: collapse;
  width: 100%;
  background-color: white;
  box-shadow: 0 0 0.4rem grey;
  /* margin-left:0.5rem;
  margin-right: 0.5rem; */
  max-width: 60rem;
  margin:0 auto;
}

th,
td {
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #f1f1f1;
  /* background-color: #f3d3a8; */
}
th{
  color:white;
  background-color:#a32cc4;

 
}

.team-h2{
  margin-bottom: 3rem;
}

.team-h2a{
  margin-top: 4rem;
}

td{
color: #616161;
}

.team-table{
  max-width: 35rem;
}

/* .team-table tr{
  text-transform:uppercase;
} */

.team-table1 tr {
  text-transform:none;
}

.table-headers{
  margin-top: 3rem;
}
/*Services*/

.services-cover{
    /* background-color: rgb(111, 111, 219); */
    /* background-color: #ececec; */
    background-color: #fff2b8;
    background-color: #e7edf8;
}

.services-container{
  padding-top: 1rem;
}

.service-container{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  background-color: rgba(213, 225, 231, 0);
  background-color: rgb(255, 255, 255);
  box-shadow: 0 0 0.6rem rgb(60, 89, 104);
  margin: 2rem 0;
  padding:2rem 0;
}

.odd-service-container{
  flex-direction: row-reverse;
}

.service-title, .service-slides{
  flex:1 30rem;
  max-width: 30rem;
  margin:1rem;
}

.service-title{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.service-title ul{
  color:#333e50;
    color:#2477bb;
  font-size: 1.2rem;
}
.service-title p{
color: #2477bb;
    font-size: 1.2rem;
    text-align: center;
    /* text-align: justify; */
    
}

.service-desc{
  color: #f38c37;
    font-size: 1.3rem;
    text-align: center;
}


.service-h3{
  color:#910c11;
  color:#e9b61d;
}

.services-slides .d-block{
  height: 15rem;
 object-fit: cover; 
}

.projects-slides .d-block {
  height: 20rem;
  object-fit: cover;
}

.services-h2{
  padding-top: 3rem;
}

/*Clients*/

.clients-cover{
    padding: 3rem 0;
    background-color: #ececec;
}

.clients{
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  background-color: white;
  
}

.client{
  flex:1 20rem;
    flex: 1 15rem;
  margin:1rem;
  max-width: 25rem;
  max-width: 15rem;
  height: 15rem;
  
}

.client p{
  text-align: center;
}

.client-image{
  height: 100%;
  object-fit: contain;
}

.clients-para{
  margin:0 auto;
  color:rgb(74, 74, 74);
  max-width: 50rem;
  padding:0 1rem;
  text-align: center;
  margin-bottom: 2rem;
  font-size: 1.1rem;
  margin-top: 2rem;
  
}


/*Gallery*/


.gallery-cover{
background-color: rgb(238, 238, 249);
}

.gallery-h2{
  padding-top: 3rem;
  margin-bottom: -1rem;
}

.gallery-h2a{
  padding-top: 1rem;
  margin-bottom: 0;
}


.gallery-image{
  width:100%;
  height:100%;
    object-fit: cover;
    border-radius: 0.3rem;
    box-shadow: 0 0 0.6rem rgb(73, 73, 73);   
}





.gallery{
 
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  max-width: 100%;
  padding-bottom: 2rem;
}

.gallery1{
padding-top: 1rem;
margin-top: -0.5rem;
padding-bottom: 1rem;
}

.gallery2{
 padding-top: 3rem; 
 /* padding-bottom: 1rem; */
}
.gallery2 .gallery-content{
  margin-bottom:6rem;
}

.gallery-content{
  
  flex:1 25rem;
  margin:1rem;
  margin-bottom: 4rem;
  max-width: 25rem;
  height:20rem;
  
}



@media screen and (max-width:400px){
  .gallery-content {

      height: 15rem;
    }
}

.gallery-content1{
max-width: 1190px;
height: auto;
}

.gallery-content p{
 
  margin-top: 0.5rem;
  color:#040744;
  color:rgb(15, 64, 137);
  font-size: 1.1rem;
  text-align: center;
}

/*Contact*/

.contact-cover{
background-color: #fff2b8;
background-color: #f1beff;
padding-top: 1rem;
}

form {
display: flex;
align-items: flex-start;
flex-direction: column;
width: 100%;
font-size: 16px;

}

     input {
       width: 100%;
       height: 35px;
       padding: 7px;
       outline: none;
       border-radius: 5px;
       border: 1px solid rgb(220, 220, 220);
       box-shadow: 0 0 0.2rem grey;
     }

     input:focus {
       border: 2px solid rgb(37, 85, 157);
     }

textarea {
  max-width: 100%;
  min-width: 100%;
  width: 100%;
  max-height: 100px;
  min-height: 100px;
  padding: 7px;
  outline: none;
  border-radius: 5px;
  border: 1px solid rgb(220, 220, 220);
  box-shadow: 0 0 0.2rem grey;
}

textarea:focus {
  border: 2px solid rgb(37, 85, 157);
}

label {
  margin-top: 1rem;
  color:#260030;
  
}

 input[type="submit"] {
   margin-top: 1rem;
   cursor: pointer;
  background: #260030;
   color: white;
   border: none;
   transition: 0.5s;
 }

 input[type="submit"]:hover {
  background: #a32cc4;
 }

 .contact-section{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  /* justify-content: flex-end; */
  align-items: center;
  padding-top: 0;
  /* margin-top: 3rem; */

 }

 .contact-slides,.form-cover{
  flex:1 30rem;
  max-width: 30rem;
  margin:1rem;
 }

 /* .contact-slides{
    box-shadow: 0.5rem -0.5rem 0 #e9b61d;
 } */

 .contact-slides-cover .d-block{
height: 25rem;
 }

 /*WhatsApp Icon*/

    /* for desktop */
    .whatsapp_float {
      position: fixed;
      width: 60px;
      height: 60px;
      bottom: 40px;
      left: 40px;

      background-color: #25d366;
      color: #FFF;
      border-radius: 50px;
      text-align: center;
      font-size: 30px;
      box-shadow: 2px 2px 3px #999;
      z-index: 100;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 0.5s;
    }
  
    .whatsapp_float:hover{
      background-color: #167539;
    }
    .whatsapp-icon {
      color:white;
    }

    .whatsapp-icon:hover{
      color:white;
    }
  
    /* for mobile */
    @media screen and (max-width: 767px) {
      .whatsapp-icon {
        /* margin-top: 10px; */
      }
  
      /* .whatsapp_float {
        width: 40px;
        height: 40px;
        bottom: 20px;
        left: 10px;
        font-size: 22px;
      } */
    }

    /* .home-quote{
      padding:3rem 0;
      background: linear-gradient(rgb(39, 39, 50), rgb(36, 36, 48)),url('./images/31.jpg');
    } */

    .quote-content{
      width:90vw;
      max-width: 50rem;
      margin:0 auto;
    }

    .home-quote p{
      color:white;
      font-size: 1.2rem;
      text-align: center;
      /* font-style: italic; */
      position: relative;
    }

    .quote-cover{
      position: absolute;
      color: #26abe3;
      color:#f04a00;
      font-size: 2.5rem;
      top:-4rem;
    }

    .quote-h3{
      font-size: 2rem;
      text-align: center;
      color:#26abe3;
      color:#f04a00;
    }

    .quote-h3-a{
    font-size: 2.5rem;
      text-align: center;
      color: #ffffff;
    }

    .pledge-line{
      width:7rem;
      height: 0.25rem;
      background-color:#ffffff;
      margin: 0 auto;
      margin-bottom: 2rem;
    }

    /*Back to top*/
    

    .back-top-cover{
      border: none;
      cursor: pointer;
      width:3.5rem;
      height: 3.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      position: fixed;
      right:40px;
      bottom:40px;
      background-color:#f27840;
      border-radius: 50%;
      box-shadow: 0 0 0.3rem grey;
      z-index:3;
      transition: 0.5s;
      animation: top 2s linear infinite;
    }

  .back-top-cover:hover{
background-color: #f27840;
  }

    @keyframes top{
0%{
transform: scale(1);
}

50%{
transform: scale(1.2);
}

100%{
transform: scale(1);
}



    }

    .top-arrow{
      margin-top: -0.5rem;
      color:white;
      font-size: 2rem;
      display: inline-block;
      line-height: 2rem;
    }

    .hide{
      display: none;
    }

    /*New Core Values*/
    .core-values-container1{
      width:100%;
      margin-top: 2rem;
    }

    .core-value{
      box-shadow: 0 0 0.2rem grey;
      border-radius: 0.3rem;
      padding:0.5rem;
      margin:1rem;
      
    }

    .core-value h3 {
      font-size: 1.2rem;
      color: rgb(37, 85, 157);
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
    }

    .core-value p{
      border-top: 0.13rem solid rgb(149, 198, 210); 
      padding-top: 1rem;
    }

    .core-value-icons{
      font-size: 2rem;
      border-radius: 50%;
      background-color: rgb(226, 153, 43);
      width:2rem;
      height:2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      margin-top: 0.5rem;
    }

    .core-value-icons span{
      display: inline-block;
      margin-top: -0.5rem;
    }

    /*Footer*/

    
    .footer-content{
      display: flex;
      justify-content: space-around;
      align-items: flex-start;
      flex-wrap: wrap;
      max-width:90%;
      margin:0 auto;
      
    }

    .footer-inner-content{
      
      flex:1 15rem;
      max-width: 20rem;
      margin:1rem;
      display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        text-align: center;
    }

   

    .footer-cover{
      background-color: rgba(4, 7, 68, 0.894);
      background-color: rgba(0, 0, 0, 0.563);
      background-color:rgb(37, 85, 157); 
      
      color:white;
      border-top: 0.3rem solid #f99b6f;
      padding-bottom: 0;
      padding-top: 3rem;
      
    }

    .footer-link a{
      text-decoration: none;
      color:white;
      transition: 0.5s;
      display: inline-block;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

        .footer-link a:hover {       
      color:#f99b6f;        
        }

    .footer-link span{
      display: inline-block;
    }


    .footer-icons{
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .footer-icons span{
  font-size: 2rem;
  margin:0.3rem;
  cursor: pointer;
  color:#5dafe1;
  transition: 0.5s;
    }

        .footer-icons span:hover {       
          color:#2477bb;       
        }

.footer-inner-content h3{
  color:rgb(207, 223, 239);
  color:#f99b6f;
  font-size: 1.5rem;
}

.footer-a{
  margin-top: 0.3rem;
}

.footer-bottom{
  text-align: center;
  background-color:#2a2462;
  background-color:#a32cc4;
  background-color: #260030;
    padding: 1rem 0;
    padding-bottom: 0.5rem;
    font-size: 1rem;
    border-top: 0.3rem solid#f99b6f;
    margin-top: 2rem;
}

.footer-bottom p {
  font-size: 0.9rem;
}

.footer-link-a {
  text-decoration: none;
  color:rgb(239, 223, 141);
  transition: 0.5s;
}

.footer-link-a:hover {
  
  color: #f04a00;
}

.footer-vm{
  text-align: justify;
 
}

.buildings .d-block{
height: 25rem;
border-radius:0;
}

/* .certifications-cover{
  margin:0 auto ;
  display: flex;
  justify-content:center;
  flex-wrap: wrap;
align-items: center;
  
}
.all-certs{
  background-color: aqua;
  margin: 0 auto;
  max-width: 1100px;
  
}
.certification-image{
  display: inline-block;
  flex:1 50%;
  max-width: 25rem;
} */

.certification-image{
  width:100%;
  margin:1rem 0;
}

.cert-cover{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin:0 auto; 
}


.cert-cover div{
  flex:1 60rem;
  max-width: 60rem;
}

.email-link{
  text-align: none;
  transition: 0.5s;
  color:white;
  text-decoration: none;
}

.email-link:hover {
color: #faec9d;
}

.success-box {
  background-color: rgb(186, 218, 186);
  border: 0.15rem solid rgb(79, 172, 79);
  padding: 0.3rem 1rem;
  color: rgb(79, 172, 79);
  margin: 0 auto;
  margin-top: 1rem;
}

.error-box {
  background-color: rgb(244, 233, 233);
  border: 0.2rem solid rgb(149, 45, 45);
  padding: 0.5rem 1rem;
  color: rgb(149, 45, 45);
  margin: 0 auto;
  margin-top: 1rem;
}

.goals-list{
  text-align: center;
max-width: 12rem;
 margin: 0 auto;
max-width: 14rem;
}

.goals-list1{
  max-width: 100%;
}

.goals-list-a{
max-width: 21rem;
}



.line1{
  background-color:#f04a00;
  width:10rem;
  height: 0.25rem;
  margin:0 auto;
  margin-bottom: 2rem;
  position: relative;
}

.line2 {
  background-color:#65c164;
  width: 1rem;
  height: 1rem;
  position: absolute;
  top: 50%;
  left:50%;
  transform: translate(-50%,-50%);
  border-radius: 50%;
}

/* .table-container p{
  text-align: center;
  font-size: 1.5rem;
color: rgb(15, 64, 137);
font-weight: bold;
} */
.chart-p{
  font-size: 1.5rem;
    color: rgb(15, 64, 137);
    text-align: center;
    font-weight: bold;
}
/* .projects-p{
  font-size: 1.5rem;
  text-align: center!important;
  margin: 0 auto!important;
} */

.goal-p{
  text-align: justify!important;
}

.vision-slides .d-block{
  height:20rem;
  object-fit: cover;
}

.home-service-image{
  height:15rem;
  object-fit: cover;
}

.home-link{
  background-color: #f38c37;
  padding:0.3rem 1rem;
  transition: 0.5s;
 margin-top: 1rem;
 color:#ffffff;
 text-decoration: none;
}

.home-link:hover{
 background-color:#ffffff;
 color:#f38c37;
}

.read-more-link{
  color: #f38c37;
  text-decoration: none;
  transition: 0.5s;
}

.read-more-link:hover {
color: #333e50;
}

.services-container-a{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 3rem
}

.goals-list2{
  flex:1 10rem;
  max-width: 20rem;
}